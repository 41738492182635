<div class="custom-select-container">
  <form [formGroup]="ngForms">
    <ngx-select
      [hidden]="showCustomInput"
      [items]="_list"
      [noAutoComplete]="true"
      formControlName="customValue"
      (ngModelChange)="selectValueChange($event)"
    ></ngx-select>
    <div *ngIf="showCustomInput && !_type">
      <input
        type="text"
        name="customValue"
        formControlName="customValue"
        (ngModelChange)="inputValueChange($event)"
        [patterns]="customPatterns"
        mask="9999999999"
      >
      <!-- <input
        type="text"
        name="_value"
        formControlName="_value"
        (ngModelChange)="inputValueChange($event)"
        [disabled]="_disabled"
        currencyMask
        [options]="{ prefix: ' ', thousands: '', decimal: '', max: 99999999999 }"
      > -->
      <i
        class="fas fa-times custom-input-close"
        (click)="closeCustomField()">
      </i>
    </div>
    <div *ngIf="showCustomInput && _type === 'currency'">
      <input
        type="text"
        name="customValue"
        formControlName="customValue"
        (ngModelChange)="inputValueChange($event)"
        currencyMask
      >
      <i
        class="fas fa-times custom-input-close"
        (click)="closeCustomField()">
      </i>
    </div>
  </form>
</div>
