import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EagleQuotePasswordModalComponent } from '../components/modals';
import { EagleGeneralService, TriggerService } from '../services';

@Injectable( { providedIn: 'root' } )
export class EagleQuoteGuard implements CanActivate {
  constructor (
    private router: Router,
    private modalService: BsModalService,
    private _generalService: EagleGeneralService,
    private _triggerService: TriggerService
  ) { }

  canActivate ( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) {
    const password = localStorage.getItem('token_password');
    if (password) {
      // logged in so return true
      this._generalService.loadAuthentication({password}).subscribe(res => {
        if (res) {
          localStorage.setItem('eagle_auth', res);
          this._triggerService.toggleModalPassword();
        }
      });
      return true;
    }

    // not logged will open the login modal
    this.openPasswordModal();
    return true;
  }

  openPasswordModal() {
    const modalRef = this.modalService.show(
      EagleQuotePasswordModalComponent,
      {
        initialState: {},
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'eagle-qoute-password'
      }
    );

    (<EagleQuotePasswordModalComponent>modalRef.content).onClose.subscribe(res => {
      if (res) {
        console.log('lol');
        this._triggerService.toggleModalPassword();
      }
    });
  }
}
