<div class="eagle-quote-wrapper">
  <div class="engine-header">
    <h3>
      <i class="fas fa-chevron-left" (click)="close()"></i>
      Audit Details [{{auditDetails?.auditLogId}}]
    </h3>
    <div class="more-btn">
      <button
        class="btn next"
        [disabled]="auditActiveIndex < 1"
        (click)="next()"
      >
        <i class="fas fa-arrow-up"></i>
        Next
      </button>
      <button
        class="btn prev"
        [disabled]="auditActiveIndex >= (auditLogsCount-1)"
        (click)="prev()"
      >
        <i class="fas fa-arrow-down"></i>
        Prev
      </button>
    </div>
  </div>

  <div class="content-container">
    
    <div class="overview-section">
      <div class="overview-details">
        <div class="info">
          <p class="label">
            Audit Log ID:
          </p>
          <p class="value">
            {{auditDetails?.auditLogId}}
          </p>
        </div>

        <div class="info">
          <p class="label">
            Event:
          </p>
          <p class="value">
            {{auditDetails?.eventType}}
          </p>
        </div>

        <div class="info">
          <p class="label">
            Application ID:
          </p>
          <span class="value" [ngClass]="{ 'hover': !isApplicationDetails }" (click)="openApplication(auditDetails?.applicationId)">
            {{auditDetails?.applicationId ? auditDetails?.applicationId : ''}}
          </span>
        </div>

        <div class="info">
          <p class="label">
            Log Type:
          </p>
          <p class="value">
            {{auditDetails?.logType}}
          </p>
        </div>

        <div class="info">
          <p class="label">
            Message:
          </p>
          <p class="value">
            {{auditDetails?.logMessage}}
          </p>
        </div>

        <div class="info">
          <p class="label">
            Log Date:
          </p>
          <p class="value">
            {{_formatter.formatter(auditDetails?.createdDate, 'DD MMM YYYY [at] H:mm')}}
          </p>
        </div>

        <div class="info">
          <p class="label">
            Request URL:
          </p>
          <p class="value">
            {{auditDetails?.requestUrl}}
          </p>
        </div>

        <div class="info">
          <p class="label">
            IP Address:
          </p>
          <p class="value">
            {{auditDetails?.ipAddress}}
          </p>
        </div>

        <div class="info">
          <p class="label">
            Quote ID:
          </p>
          <p class="value">
            {{auditDetails?.quoteId}}
          </p>
        </div>
      </div>
    </div>

  </div>

</div>
