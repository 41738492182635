<div class="bnb-pagination" *ngIf="!_isOnePage">
  <ul class="pagination">
    <li class="page-item previous" [ngClass]="{ disabled: _current <= 1 }">
      <a class="page-link" (click)="prevIndex()" aria-label="Previous">
        <i class="fas fa-angle-left"></i>
        Prev
      </a>
    </li>
    <li
      class="page-item"
      *ngFor="let item of _activePages"
      [ngClass]="{ active: item.isActive, 'separate disabled': item.isDisable }"
    >
      <a class="page-link" (click)="jumpIndex(item.index)">
        {{
          item.isDisable ? '...' : item.index
        }}
      </a>
    </li>
    <li
      class="page-item next"
      [ngClass]="{ disabled: _current >= _totalPages }"
    >
      <a class="page-link" (click)="nextIndex()" aria-label="Next">
        Next
        <i class="fas fa-angle-right"></i>
      </a>
    </li>
  </ul>
</div>
