import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { INgxSelectOptions, NgxSelectModule } from 'ngx-select-ex';
import { Modals } from './components/modals';
import { CustomSelectComponent } from './components/custom-fields/custom-select/custom-select.component';
import { WarningIcnComponent } from './components/icons/warning-icn/warning-icn.component';
import { Services } from './services';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationComponent } from './components/pagination/pagination.component';
import { TimeagoModule, TimeagoClock } from 'ngx-timeago';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { Observable, interval } from 'rxjs';
import { InsAppModals } from 'app/modules/ins-app-admin/components/modals';

export const customCurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  allowZero: true,
  decimal: '.',
  precision: 0,
  prefix: '$ ',
  suffix: '',
  thousands: ',',
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};

const CustomSelectOptions: INgxSelectOptions = { // Check the interface for more options
  optionValueField: 'Value',
  optionTextField: 'Name',
  keepSelectedItems: false
};

const maskConfig: Partial<IConfig> = {
  validation: false,
};

export class MyClock extends TimeagoClock {
  tick(then: number): Observable<number> {
    return interval(2000);
  }
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSelectModule.forRoot(CustomSelectOptions),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimeagoModule.forRoot({
      clock: {
        provide: TimeagoClock,
        useClass: MyClock
      }
    }),
    NgxMaskModule.forRoot(maskConfig),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
  ],
  declarations: [
    ...Modals,
    CustomSelectComponent,
    WarningIcnComponent,
    PaginationComponent,
    ...InsAppModals
  ],
  exports: [
    ...Modals,
    CustomSelectComponent,
    WarningIcnComponent,
    PaginationComponent
  ],
  providers: [...Services],
  entryComponents: [
    ...InsAppModals
  ]
})
export class SharedAppModule { }
