import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

const baseUrl = environment.eagleApiUrl;

@Injectable({
  providedIn: 'root'
})
export class EagleGeneralService {

  constructor(private _http: HttpClient) { }

  loadAuthentication(data): Observable<any> {
    return this._http.post(`${baseUrl}/api/Authenticate`, data, { responseType: 'text' });
  }

  getUsers(data): Observable<any> {
    return this._http.post(`${baseUrl}/api/GetAllUsers`, data);
  }

  updateUser(data): Observable<any> {
    return this._http.post(`${baseUrl}/api/UpdateUser`, data);
  }

  exportUsersList(data): Observable<any> {
    return this._http.post(`${baseUrl}​/api/export-user-to-xlsx`, data);
  }

  getAuditLogs(data): Observable<any> {
    return this._http.post(`${baseUrl}/api/AuditLog`, data);
  }

  passwordReset(data): Observable<any> {
    return this._http.post(`${baseUrl}​/api/ResetPassword`, data);
  }

  deleteUser(data): Observable<any> {
    return this._http.post(`${baseUrl}​/api/DeleteUser?Id=${data.id}`, {});
  }

  getAuditLogEvents(): Observable<any> {
    return this._http.get(`${baseUrl}​/api/get-list-of-event-types`);
  }

  getUserDetails(guid): Observable<any> {
    return this._http.get(`${baseUrl}​/api/get-user-by-id?id=${guid}`);
  }
}
