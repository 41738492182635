import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'warning-icn',
  templateUrl: './warning-icn.component.html',
  styleUrls: ['./warning-icn.component.scss']
})
export class WarningIcnComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
