import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

const baseUrl = environment.insApiUrl;

@Injectable({
  providedIn: 'root'
})
export class InsAppService {

  constructor(private _http: HttpClient) { }

  getApplications(data): Observable<any>{
    return this._http.get(`${baseUrl}/api/applications`, { params: data });
  }

  getDetails(id): Observable<any>{
    return this._http.get(`${baseUrl}/api/applications/${id}`);
  }

  getClients(id): Observable<any>{
    return this._http.get(`${baseUrl}/api/applications/${id}/clients`);
  }

  getEsign(id): Observable<any>{
    return this._http.get(`${baseUrl}/api/applications/${id}/esign`);
  }

  updateApplicationStatus(appId, statusId): Observable<any>{
    return this._http.post(`${baseUrl}/api/applications/${appId}/update-status/${statusId}`, {});
  }

  downloadSigned(id): Observable<any>{
    return this._http.get(`${baseUrl}/api/applications/${id}/esign-download`, { responseType: 'arraybuffer' });
  }

  downloadUnsigned(id): Observable<any>{
    return this._http.get(`${baseUrl}/api/applications/${id}/unsigned-download`, { responseType: 'arraybuffer' });
  }

  cancelEsign(id): Observable<any>{
    return this._http.post(`${baseUrl}/api/applications/${id}/esign-cancel`, {});
  }

  getAuditLogs(data): Observable<any>{
    return this._http.get(`${baseUrl}/api/applications/get-all-audit-logs`, { params: data });
  }

  getAuditLogsByApplication(applicationId, data): Observable<any>{
    return this._http.get(`${baseUrl}/api/applications/${applicationId}/audit-log`, { params: data });
  }
}
