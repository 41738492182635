import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

const baseUrl = environment.eagleApiUrl;

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private router: Router,
    private _http: HttpClient
  ) { }

  login(data): Observable<any>{
    return this._http.post(`${baseUrl}/api/signin`, data);
  }

  refreshToken(data): Observable<any> {
    return this._http.post(`${baseUrl}/api/refresh-token`, data);
  }

  logout(data): Observable<any> {
    const token = localStorage.getItem('eagle_auth');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    })
    return this._http.post(`${baseUrl}/api/sign-out`, data, { headers });
  }
}
