import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

@Injectable( { providedIn: 'root' } )
export class AuthGuard implements CanActivate {
  constructor (
    private router: Router,
  ) { }

  canActivate ( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) {
    const auth = localStorage.getItem('eagle_auth');
    if (auth) {
      // logged in so return true
      return true;
    }

    // not logged will open the login modal
    this.router.navigate(['/login']);
    return true;
  }
}
