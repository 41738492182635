import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from '../services';
import { Router } from '@angular/router';

@Injectable()
export class EagleJwtInterceptor implements HttpInterceptor {

  constructor (
    private router: Router,
    private _loginService: LoginService
  ) { }

  checkRequest ( url: string ) {
    const split = url.split( '/' );
    return (
      ( !split.find(i => i === 'refresh-token') ) ||
      false
    );
  }

  intercept (
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const token = localStorage.getItem('eagle_auth');
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    } else if ( !token ) {
      // const obj = {
      //   isLogin: false
      // };
      // // store user details and jwt token in local storage to keep user logged in between page refreshes
      // localStorage.setItem( 'currentUser', JSON.stringify( obj ) );
    }

    
    if ( this.checkRequest( request.url ) ) {
      const postData = {
        refreshToken: localStorage.getItem('refresh_token'),
        email: localStorage.getItem('email')
      };
      this._loginService.refreshToken(postData).subscribe(res => {
        if (localStorage.getItem('eagle_auth')) {
          localStorage.setItem('eagle_auth', res.authTokens.idToken);
          localStorage.setItem('refresh_token', res.authTokens.refreshToken);
          localStorage.setItem('accessToken', res.authTokens.accessToken);
        }
      }, err => {
        console.error(err);
        localStorage.removeItem('eagle_auth');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('accessToken');
        this.router.navigate(['/login']);
      });
    }

    // return next.handle( request );
    return next.handle(request.clone());
  }
}
