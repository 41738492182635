import { ConfirmationModalComponent } from "./confirmation-modal/confirmation-modal.component";
import { EagleQuotePasswordModalComponent } from "./eagle-quote-password-modal/eagle-quote-password-modal.component";
import { QouteOptionsComponent } from "./qoute-options/qoute-options.component";

export const Modals = [
  QouteOptionsComponent,
  EagleQuotePasswordModalComponent,
  ConfirmationModalComponent
];

export * from "./confirmation-modal/confirmation-modal.component";
export * from "./qoute-options/qoute-options.component";
export * from "./eagle-quote-password-modal/eagle-quote-password-modal.component";
