<div class="eagle-password-container">
  <label class="title">
    Login Page
  </label>
  <form [formGroup]="ngForms">
    <div class="form-engine-group">
      <label>
        Enter the access token that contains the security credentials.
      </label>
      <input
        type="password"
        name="tokenPassword"
        formControlName="tokenPassword"
      >
    </div>
  </form>
  <div class="action-buttons">
    <button class="btn btn-default" (click)="cancel()">
      Cancel
    </button>
    <button class="btn btn-success" [disabled]="ngForms.invalid || isAccessing" (click)="access()">
      {{ isReApply ? 'Reaccess' : 'Access' }}
    </button>
  </div>
</div>