import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EagleQuotePasswordModalComponent } from '../components/modals';
import { Router } from '@angular/router';

@Injectable()
export class EagleErrorInterceptor implements HttpInterceptor {
  constructor(
    private modalService: BsModalService,
    private router: Router
  ) {}

  showNotification() {
    // this._notification.create('danger', `Oops someting's not right`, {
    //   Position: 'top-right',
    //   Style: 'flip',
    //   Duration: 3000
    // });
  }

  openPasswordModal() {
    const modalRef = this.modalService.show(
      EagleQuotePasswordModalComponent,
      {
        initialState: {
          isReApply: true
        },
        ignoreBackdropClick: true,
        keyboard: false,
        class: 'eagle-qoute-password'
      }
    );
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (err.status === 401) {
          localStorage.removeItem('eagle_auth');
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('accessToken');
          this.router.navigate(['/login']);
        }

        let errorrMessage = '';
        if (err) {
          if (err.hasOwnProperty('error')) {
            if (err.error.hasOwnProperty('message')) {
              errorrMessage = err.error.message;
            }
          } else if (err.hasOwnProperty('statusText')) {
            errorrMessage = err.statusText;
          } else {
            errorrMessage = err;
          }
        }
        const error = errorrMessage;
        return throwError(error);
      })
    );
  }
}
