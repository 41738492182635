<div class="qoute-options-container">
  <label class="title">
    Quote Settings
  </label>
  <form [formGroup]="ngForms">
    <div class="row">
      <div class="col-6">
        <div class="form-engine-group">
          <label>Calculation Date</label>
          <input
            type="text"
            name="calcDate"
            formControlName="calcDate"
            bsDatepicker
            [bsConfig]="bsConfig"
          >
        </div>
      </div>
      <div class="col-6">
        <div class="form-engine-group">
          <label>Payment Frequency</label>
          <ngx-select 
            [items]="paymentFrequencyOptions"
            [noAutoComplete]="true"
            formControlName="paymentFrequency"
          ></ngx-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-engine-group">
          <label>AIA Vitality Discount</label>
          <div class="form-radio-button">
            <div class="form-radio">
              <input
                name="vitalityDiscountSelected"
                formControlName="vitalityDiscountSelected"
                id="vitalityDiscountSelectedYes"
                type="radio"
                [value]="true"
              />
              <label for="vitalityDiscountSelectedYes">Yes</label>
            </div>
            <div class="form-radio">
              <input
                name="vitalityDiscountSelected"
                formControlName="vitalityDiscountSelected"
                id="vitalityDiscountSelectedNo"
                type="radio"
                [value]="false"
              />
              <label for="vitalityDiscountSelectedNo">No</label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-engine-group" [ngClass]="{ 'disabled': isVitalityFeeDisabled }">
          <label>AIA Vitality Fee</label>
          <div class="form-radio-button">
            <div class="form-radio">
              <input
                name="vitalityFeeSelected"
                formControlName="vitalityFeeSelected"
                id="vitalityFeeSelectedYes"
                type="radio"
                [value]="true"
              />
              <label for="vitalityFeeSelectedYes">Yes</label>
            </div>
            <div class="form-radio">
              <input
                name="vitalityFeeSelected"
                formControlName="vitalityFeeSelected"
                id="vitalityFeeSelectedNo"
                type="radio"
                [value]="false"
              />
              <label for="vitalityFeeSelectedNo">No</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="action-buttons">
    <button class="btn btn-default" (click)="close()">
      Cancel
    </button>
    <button class="btn btn-success" (click)="apply()">
      Apply
    </button>
  </div>
</div>
