import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RouterDataHandlerService {

  private eagleQuoteChildActive: string = '';

  @Output()
  eagleQuoteHandler: EventEmitter<string> = new EventEmitter();

  constructor() { }

  setEagleQuoteActiveRoute(route) {
    this.eagleQuoteChildActive = route;
    this.eagleQuoteHandler.emit(this.eagleQuoteChildActive);
  }

  getEagleQuoteActiveRoute() {
    return this.eagleQuoteChildActive;
  }
}
