import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  _maxLenght: number = 5;
  _totalPages: number = 0;
  _current = 1;
  _isOnePage: boolean = false;
  _firstIndex = 1;
  _lastIndex = Infinity;
  _pages: Array<any> = [];
  _tempPages: Array<any> = [];
  _activePages: Array<any> = [];

  @Input()
  set TotalPages(pages: number) {
    if (!pages || pages <= 1) {
      this._isOnePage = true;
      return;
    }
    this._isOnePage = false;
    this._totalPages = pages;
    this.buildIndexes();
  }

  @Input()
  LimiterShow: boolean;

  @Input()
  set ActivePage(page: number) {
    this._current = page;
    this.buildIndexes();
  }

  get PageIndex(): number {
    return this._current;
  }

  @Output() PageIndexChange: EventEmitter<number> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  jumpIndex(idx) {
    if (idx === this._current) {
      return;
    }
    this._current = idx;
    this.PageIndexChange.emit(this.PageIndex);
    this.buildIndexes();
  }

  prevIndex() {
    this._current--;
    this.PageIndexChange.emit(this.PageIndex);
    this.buildIndexes();
  }

  nextIndex() {
    this._current++;
    this.PageIndexChange.emit(this.PageIndex);
    this.buildIndexes();
  }

  buildIndexes() {
    this._pages = [];
    if (this._totalPages <= this._maxLenght) {
      for (let i = 1; i <= this._totalPages; i++) {
        const obj = {
          index: i,
          status: 'show',
          isActive: i === this._current,
          isDisable: false
        };
        this._pages.push(obj);
      }
      this.getActiveIndexes();
      return;
    }

    if (this._current <= 4) {
      let isDisableSet = false;
      for (let i = 1; i <= this._totalPages; i++) {
        const obj = {
          index: i,
          status: 'show',
          isActive: i === this._current,
          isDisable: false
        };
        if (i > 5 && i !== this._totalPages) {
          if (isDisableSet) {
            obj.status = 'hide';
          } else {
            obj.isDisable = true;
            isDisableSet = true;
          }
        }
        this._pages.push(obj);
      }
    } else if (this._current >= this._totalPages - 3) {
      let isDisableSet = false;
      for (let i = 1; i <= this._totalPages; i++) {
        const obj = {
          index: i,
          status: 'show',
          isActive: i === this._current,
          isDisable: false
        };
        if (i < this._totalPages - 4 && i !== 1) {
          if (isDisableSet) {
            obj.status = 'hide';
          } else {
            obj.isDisable = true;
            isDisableSet = true;
          }
        }
        this._pages.push(obj);
      }
    } else {
      const prev = this._current - 1;
      const next = this._current + 1;
      this._tempPages = [prev, this._current, next];
      let isDisableSet = false;
      let countIncludes = 0;
      for (let i = 1; i <= this._totalPages; i++) {
        const obj = {
          index: i,
          status: 'show',
          isActive: i === this._current,
          isDisable: false
        };
        if (i !== 1 && i !== this._totalPages) {
          if (this._tempPages.includes(i)) {
            countIncludes++;
            if (countIncludes === 3) {
              isDisableSet = false;
            }
          } else if (isDisableSet) {
            obj.status = 'hide';
          } else {
            obj.isDisable = true;
            isDisableSet = true;
          }
        }
        this._pages.push(obj);
      }
    }
    this.getActiveIndexes();
  }

  getActiveIndexes() {
    this._activePages = this._pages.filter(i => i.status === 'show');
  }
}
