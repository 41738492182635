<div class="confirmation-modal">
  <h3>
    {{title}}
  </h3>
  <p>
    {{content}}
  </p>
  <div class="action-buttons">
    <button class="btn btn-default" (click)="close()">
      {{ cancelLabel ? cancelLabel : 'Cancel' }}
    </button>
    <button
      class="btn btn-success"
      [ngClass]="comfirmationStatus"
      (click)="ok()"
      [disabled]="isButtonDisabled"
    >
      {{ buttonLabel ? buttonLabel : 'Ok' }}
    </button>
  </div>
</div>