import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class QuoteEngineService {

  constructor(private _http: HttpClient) { }

  calculate(data): Observable<any> {
    return this._http.post(`${baseUrl}/quoteV3/UI`, data);
  }

  getQuoteList(params): Observable<any> {
    return this._http.get(`${baseUrl}/quote/UI/list-quotes`, { params });
  }

  getQuote(id): Observable<any> {
    return this._http.get(`${baseUrl}/quote/UI/quotes/${id}`);
  }

  saveQuoteName(data): Observable<any> {
    return this._http.post(`${baseUrl}/quote/UI/save-quote/name`, data);
  }

  saveQuote(data): Observable<any> {
    return this._http.post(`${baseUrl}/quote/UI/save-quote`, data);
  }

  deleteQuote(id): Observable<any> {
    return this._http.delete(`${baseUrl}/quote/UI/quotes/${id}`);
  }
}
