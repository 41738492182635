import { Injectable, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TriggerService {
  
  private tokenPassword: string = '';
  tokenObservable = new BehaviorSubject('');
  token = this.tokenObservable.asObservable();

  @Output() passwordUpdate: EventEmitter<any> = new EventEmitter();
  subsVar: Subscription

  constructor() { }

  // trigger services
  toggleModalPassword() {
    this.passwordUpdate.emit(null);
  }

  setToken(token) {
    this.tokenObservable.next(token);
  }
}
