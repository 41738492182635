import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-qoute-options',
  templateUrl: './qoute-options.component.html',
  styleUrls: ['./qoute-options.component.scss']
})
export class QouteOptionsComponent implements OnInit {
  public onClose: Subject<any>;
  ngForms: FormGroup;

  paymentFrequencyOptions: Array<any> = [
    {
      Name: 'Yearly',
      Value: 1
    },
    {
      Name: 'Monthly',
      Value: 12
    },
    {
      Name: 'Fortnightly ',
      Value: 26
    }
  ];

  settingsData;
  isVitalityFeeDisabled: boolean = false;

  bsConfig = {
    dateInputFormat: 'YYYY-MM-DD',
    containerClass: 'theme-default'
  };

  constructor(
    private fb: FormBuilder,
    private bsModalRef: BsModalRef
  ) {
    this.ngForms = fb.group({
      calcDate: [],
      paymentFrequency: [],
      vitalityDiscountSelected: [],
      vitalityFeeSelected: []
    });
  }

  ngOnInit(): void {
    this.onClose = new Subject();

    this.initData();

    this.ngForms.get('vitalityDiscountSelected').valueChanges.subscribe(val => {
      if (val) {
        this.ngForms.controls.vitalityFeeSelected.enable();
        this.isVitalityFeeDisabled = false;
      } else {
        this.ngForms.controls.vitalityFeeSelected.setValue(false);
        this.ngForms.controls.vitalityFeeSelected.disable();
        this.isVitalityFeeDisabled = true;
      }
    });
  }

  initData() {
    const { calcDate, paymentFrequency, providerSettings } = this.settingsData;
    const providerObj = providerSettings[0];

    this.ngForms.controls.calcDate.setValue(calcDate);
    this.ngForms.controls.paymentFrequency.setValue(paymentFrequency);
    this.ngForms.controls.vitalityDiscountSelected.setValue(providerObj.vitalityDiscountSelected);
    this.ngForms.controls.vitalityFeeSelected.setValue(providerObj.vitalityFeeSelected);
    this.disableForm();
  }

  disableForm() {
    if (this.ngForms.controls.vitalityDiscountSelected.value) {
      this.ngForms.controls.vitalityFeeSelected.enable();
      this.isVitalityFeeDisabled = false;
    } else {
      this.ngForms.controls.vitalityFeeSelected.setValue(false);
      this.ngForms.controls.vitalityFeeSelected.disable();
      this.isVitalityFeeDisabled = true;
    }
  }

  apply() {
    const {
      calcDate,
      paymentFrequency,
      vitalityDiscountSelected,
      vitalityFeeSelected
    } = this.ngForms.value;

    const newSettingsData = {
      ...this.settingsData,
      calcDate,
      paymentFrequency,
      providerSettings: [
        {
          ...this.settingsData.providerSettings[0],
          vitalityDiscountSelected,
          vitalityFeeSelected
        }
      ]
    };
    this.close(newSettingsData);
  }

  close(response = {}) {
    this.onClose.next(response);
    this.bsModalRef.hide();
  }
}
