import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EagleGeneralService, TriggerService } from 'app/shared/services';
import { eaglequote } from 'environments/environment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-eagle-quote-password-modal',
  templateUrl: './eagle-quote-password-modal.component.html',
  styleUrls: ['./eagle-quote-password-modal.component.scss']
})
export class EagleQuotePasswordModalComponent implements OnInit {
  public onClose: Subject<string>;
  ngForms: FormGroup;

  isAccessing: boolean = false;

  // params
  isReApply: boolean = false;

  constructor(
    private fb: FormBuilder,
    private bsModalRef: BsModalRef,
    private router: Router,
    private _generalService: EagleGeneralService,
    private _triggerService: TriggerService
  ) {
    this.ngForms = fb.group({
      tokenPassword: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.onClose = new Subject();

    this.ngForms.controls.tokenPassword.setValue(eaglequote.password);
  }
  
  access() {
    if (this.isAccessing) {
      return;
    }

    this.isAccessing = true;
    const postData = {
      password: this.ngForms.value.tokenPassword
    };
    this._generalService.loadAuthentication(postData).subscribe(res => {
      if (res) {
        this.isAccessing = false;
        localStorage.setItem('token_password', postData.password);
        localStorage.setItem('eagle_auth', res);
        this._triggerService.toggleModalPassword();
        this.close();
      }
    }, err => {
      this.isAccessing = false;
      console.error(err);
    });
  }

  cancel() {
    this.close();
    this.router.navigate(['']);
    this.close();
  }

  close(response = '') {
    this.onClose.next(response);
    this.bsModalRef.hide();
  }
}
