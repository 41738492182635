import { Injectable } from '@angular/core';
import { TimeagoPipe } from 'ngx-timeago';
import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class FormattersService {

  constructor(private timePipe: TimeagoPipe) { }

  formatDate(date, formatStr = 'MMM DD, YYYY') {
    if (!date) {
      // return 'No date available';
      return '';
    }
    const dateNow = momentTimezone.tz(new Date(), 'Pacific/Tongatapu'); //Auckland
    const convertedDate = momentTimezone.tz(date, 'Pacific/Tongatapu');
    const duration = moment.duration(moment(dateNow).diff(convertedDate));
    // const duration = moment.duration(moment().diff(date));
    if (duration.asSeconds() <= 60) {
      return 'Just now';
    } else if (duration.asDays() >= 1 && duration.asDays() < 2) {
      return '1 day ago';
    } else if (duration.asDays() >= 7) {
      return moment(convertedDate).utc().format(formatStr);
    } else {
      return this.timePipe.transform(convertedDate);
    }
  }

  formatter(date, formatStr = 'MMM DD, YYYY') {
    return moment(date).utc().format(formatStr);
  }
}
