import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormattersService } from 'app/shared/services';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TimeagoPipe } from 'ngx-timeago';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-ins-app-audit-log-details-modal',
  templateUrl: './ins-app-audit-log-details-modal.component.html',
  styleUrls: ['./ins-app-audit-log-details-modal.component.scss'],
  providers: [DatePipe, TimeagoPipe, FormattersService]
})
export class InsAppAuditLogDetailsModalComponent implements OnInit {
  public onClose: Subject<boolean>;

  auditDetails;
  auditLogs: Array<any> = [];
  auditLogsCount;
  auditActiveIndex;

  isApplicationDetails;

  constructor(
    private router: Router,
    private bsModalRef: BsModalRef,
    public _formatter: FormattersService
  ) { }

  ngOnInit(): void {
    this.onClose = new Subject();
    this.auditLogsCount = this.auditLogs.length;
  }

  openApplication(applicationId) {
    if (applicationId && !this.isApplicationDetails) {
      this.router.navigate([`/ins-app-admin/applications/${applicationId}`]);
      this.close(false);
    }
  }

  next() {
    if (this.auditActiveIndex < 1) {
      return;
    }
    this.auditActiveIndex--;
    this.auditDetails = this.auditLogs[this.auditActiveIndex];
  }

  prev() {
    if (this.auditActiveIndex >= this.auditLogsCount-1) {
      return;
    }
    this.auditActiveIndex++;
    this.auditDetails = this.auditLogs[this.auditActiveIndex];
  }

  close(response = false) {
    this.onClose.next(response);
    this.bsModalRef.hide();
  }
}
