import { BenefitsService } from './benefits/benefits.service';
import { FormattersService } from './formatters/formatters.service';
import { LoginService } from './login/login.service';
import { QuoteEngineService } from './quote-engine/quote-engine.service';
import { RouterDataHandlerService } from './router-data-handler/router-data-handler.service';
import { TriggerService } from './trigger/trigger.service';

export const Services = [
  BenefitsService,
  QuoteEngineService,
  RouterDataHandlerService,
  FormattersService,
  TriggerService,
  LoginService
];

export * from './benefits/benefits.service';
export * from './current-user/current-user.service';
export * from './eagle-general-service/eagle-general.service';
export * from './login/login.service';
export * from './quote-engine/quote-engine.service';
export * from './router-data-handler/router-data-handler.service';
export * from './formatters/formatters.service';
export * from './trigger/trigger.service';
export * from './ins-app/ins-app.service';
