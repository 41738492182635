export const isFieldValid = (formGroup, field: string) => {
  if (field === 'age' || field === 'seriousTraumaCoverAmount') {
    return !(formGroup.get(field).value) && formGroup.get(field).touched;
  }
  return (formGroup.get(field) && !formGroup.get(field).valid) && formGroup.get(field).touched;
}

export const displayFieldCss = (formGroup, field: string) => {
  return {
    'has-error': isFieldValid(formGroup, field),
    'has-feedback': isFieldValid(formGroup, field)
  };
}

export const validateAllFormFields = (formGroup) => {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    control.markAsTouched({ onlySelf: true });
  });
}
